import React from "react";
import { Link, navigate } from "gatsby";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
import Layout from "../components/layout";
import axios from "axios";
import SweetAlert from "sweetalert-react";
import Dotimg from "../images/Rectangle.png";
import HeaderLight from "./../components/headerLight";
// import MyCartProducts from "../components/myCartProducts";
import { graphql } from "gatsby";
import Footer from "./../components/footer";

require("sweetalert/dist/sweetalert.css");
require("../css/myCart.css");

var result = [];
var mycartData = [];
var totalQuantity = 0,
  totalAmount = 0,
  totalDeliveryAmount = 0,
  totalGstAmount = 0;

var data = [];

var comingsoon = false;

class MyCart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAlert: false,
      cartLength: 0,
      cartData: [],
      myCartStateData: [],
      totalQuantity: 0,
      totalAmount: 0,
      totalGstAmount: 0,
      refresh: false,
      gst: 0,
      amountPayable: 0,
      deliveryCharge: 0,
      totalPricePerProductQuantity: 0,
      //anil-code//
      loginstatus: false,
      withoutLogin: false
    };
    this.postForm.bind(this);
    // this.incrementValue = this.incrementValue.bind(this);
    // this.decrementValue = this.decrementValue.bind(this);
  }

  componentDidMount() { }

  reload() {
    if (window.localStorage.getItem("cartDetails") == null) {
      window.localStorage.setItem("cartDetails", "[]");
    } else {
      var initialData = window.localStorage.getItem("cartDetails");
      // console.log("initialData", initialData);
      var parse = JSON.parse(initialData);
      this.setState({ cartData: parse });
      var len = parse.length;

      mycartData = [];
      this.setState({
        cartLength: len,
        myCartStateData: []
      });
      data = this.props.data.allWordpressWpNativeproduct.edges;
      // console.log(data);

      {
        var arr0fQuantity = [],
          arrOfAmount = [],
          arrOfDeliveryCharge = [],
          arrOfGst = [];
        if (parse.length != 0) {
          parse.map((product, index) => {
            this.getItemData(product, data);
            arr0fQuantity.push(product.quantity);
            console.log("product.quantity", product.quantity);

            if (data.length != 0) {
              data.map((e, index) => {
                if (e.node.id == product.id) {
                  var noOfQuantity =
                    parseFloat(e.node.acf.price) * product.quantity;
                  arrOfAmount.push(noOfQuantity);
                  // console.log(
                  //   "Outside delivery_charges ",
                  //   e.node.acf.delivery_charges
                  // );
                  if (e.node.acf.delivery_charges != null) {
                    // console.log(
                    //   "Inside delivery_charges ",
                    //   e.node.acf.delivery_charges
                    // );
                    var parseF = parseFloat(e.node.acf.delivery_charges);
                    // console.log("parseF", parseF);
                    var totalDeliveryChargePerItem = product.quantity * parseF;
                    console.log(
                      "totalDeliveryChargePerItem",
                      totalDeliveryChargePerItem
                    );
                    arrOfDeliveryCharge.push(totalDeliveryChargePerItem);
                  }

                  // GST Total //
                  if (e.node.acf.gst_percentage != null) {
                    if (e.node.acf.base_price != null) {
                      if (e.node.acf.gst_percentage != 0) {
                        var gstAmt =
                          ((parseFloat(e.node.acf.gst_percentage) *
                            parseFloat(e.node.acf.base_price)) /
                            100) *
                          parseFloat(e.node.quantity);
                        var numFloat = parseFloat(gstAmt.toFixed(2));
                        // console.log("nn", n, typeof n);
                        arrOfGst.push(numFloat);
                        // console.log("Inside arrOfGst", arrOfGst);
                      }
                    }
                  }

                  if (product.quantity == 1) {
                    var amountTot = e.node.acf.price;
                    data[index].node.priceTotalPerQuantity = amountTot;
                  } else {
                    var amountTot = e.node.acf.price * product.quantity;
                    data[index].node.priceTotalPerQuantity = amountTot;
                  }
                }
              });
            }
          });
        }
        // console.log("Outside arrOfGst", arrOfGst);
        if (arrOfGst.length != 0) {
          // console.log("arrOfGst.length arrOfGst", arrOfGst);
          var roundOfGstAmount = arrOfGst.reduce(getSum);
          totalGstAmount = roundOfGstAmount.toFixed(2);

          function getSum(total, num) {
            return total + num;
          }
        } else {
          totalGstAmount = 0;
        }

        // console.log("totalGstAmount", totalGstAmount);

        if (arrOfDeliveryCharge.length != 0) {
          var roundOfDeliveryBillAmount = arrOfDeliveryCharge.reduce(getSum);

          totalDeliveryAmount = roundOfDeliveryBillAmount.toFixed(2);

          function getSum(total, num) {
            return total + num;
          }
        }
        if (arrOfAmount.length != 0) {
          var roundOfBillAmount = arrOfAmount.reduce(getSum);
          totalAmount = roundOfBillAmount.toFixed(2);

          function getSum(total, num) {
            return total + num;
          }
        }
        if (arr0fQuantity.length != 0) {
          var roundOfBillAmount = arr0fQuantity.reduce(getSum);
          totalQuantity = Math.round((roundOfBillAmount * 100) / 100);

          function getSum(total, num) {
            return total + num;
          }
        }

        // console.log("totalAmount", totalAmount);
        if (parse.length != 0) {
          if (totalQuantity == undefined && totalAmount == undefined) {
            totalAmount = 0;
            totalQuantity = 0;
            this.setState({
              totalQuantity: totalQuantity,
              totalAmount: totalAmount
            });
          } else {
            this.setState(
              {
                totalQuantity: totalQuantity,
                totalAmount: totalAmount,
                totalDeliveryAmount: totalDeliveryAmount,
                gst: totalGstAmount
              },
              () => {
                if (
                  this.state.totalAmount != undefined &&
                  this.state.totalDeliveryAmount != undefined
                ) {
                  this.caluculateTotal(
                    this.state.totalAmount,
                    this.state.totalDeliveryAmount
                  );
                }
              }
            );
          }
        }
      }
    }
  }

  componentDidMount() {

    if (window.sessionStorage.getItem("email")) {
      this.setState({ loginstatus: true })
    }
    data = this.props.data.allWordpressWpNativeproduct.edges;
    this.reload();
  }

  caluculateTotal(totalAmount, totalDeliveryAmount) {
    if (totalAmount != undefined || totalDeliveryAmount != undefined) {
      var n = parseFloat(totalDeliveryAmount) + parseFloat(totalAmount);
      var tot = n.toFixed(2);
      this.setState({
        amountPayable: tot
      });
    }
  }

  getItemData(d, data) {
    // console.log("d,", d, data);
    data.forEach(function (element, i) {
      if (element.node.id == d.id) {
        element.node.quantity = d.quantity;
        if (mycartData.includes(element) == false) {
          // console.log("mycartDatamycartData", mycartData)
          mycartData.push(element);
        }
      }
    });

    this.setState({ myCartStateData: mycartData });
  }

  postForm() {
    var _this = this;
    var bodyFormData = new FormData();
    bodyFormData.set("fullName", document.getElementById("name").value);
    bodyFormData.set("email", document.getElementById("email").value);
    bodyFormData.set("number", document.getElementById("number").value);
    bodyFormData.set("organisation", document.getElementById("org").value);
    bodyFormData.set("message", document.getElementById("comment").value);
    bodyFormData.set("formID", 190);
    // console.log(_this);
    axios
      .post(
        "https://dashboard.nativecircle.in/index.php/wp-json/contact-form-7/v1/contact-forms/190/feedback",
        bodyFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      )
      .then(response => {
        // console.log(response.status);
        document.getElementById("form").reset();
        if (response.status == 200 || response.status == 201) {
          _this.setState({
            showAlert: true
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  }
  // setLocalData() {
  //    console.log("myCartData", this.state.myCartStateData)
  //   window.localStorage.setItem("amountPayable", this.state.amountPayable)
  //   window.localStorage.setItem("myCartData", JSON.stringify(this.state.myCartStateData))
  //   window.localStorage.setItem("totalQuantity", this.state.totalQuantity)
  // }
  setLocalData() {
    if (this.state.loginstatus == true) {
      console.log("myCartData", this.state.myCartStateData)
      window.localStorage.setItem("amountPayable", this.state.amountPayable)
      window.localStorage.setItem("myCartData", JSON.stringify(this.state.myCartStateData))
      window.localStorage.setItem("totalQuantity", this.state.totalQuantity)

      navigate("/payment-checkout", {
        state: {
          amountPayable: this.state.amountPayable,
          products: this.state.myCartStateData,
          totalQuantity: this.state.totalQuantity
        }
      })
    }
    else {
      this.setState({ withoutLogin: true })
    }
  }

  incrementValue(id, _this) {
    if (window.localStorage.getItem("cartDetails") == null) {
      window.localStorage.setItem("cartDetails", "[]");
    } else {
      var initialData = window.localStorage.getItem("cartDetails");
      var parse = JSON.parse(initialData);
      // console.log("before", parse);
      var array = parse;
      var totalDeliveryAmount;
      var arrObj = {},
        arrOfDeliveryCharge = [],
        arrOfGst = [];
      var mystatecart = this.state.myCartStateData;
      mystatecart.map((e, index) => {
        if (e.node.id == id) {
          if (e.node.quantity > 9) {
            // this.props.navigate("/business/");
            var data = e.node.id;
            // navigate("/business/", { state: { data }});
            navigate("/business/", { state: { data } });
          } else {
            let currentvalue = e.node.quantity;
            var incrementedValue = ++currentvalue;
            mystatecart[index].node.quantity = incrementedValue;
            arrObj["id"] = e.node.id;
            arrObj["quantity"] = e.node.quantity;
            this.setState({ quantityValue: incrementedValue });
            var amountTot = incrementedValue * e.node.acf.price;
            mystatecart[index].node.priceTotalPerQuantity = amountTot;
            // this.setState({ totalPricePerProductQuantity: amountTot });
            //---------------------- GST Total -----------------------
            if (e.node.acf.gst_percentage != null) {
              if (e.node.acf.base_price != null) {
                if (e.node.acf.gst_percentage != 0) {
                  var gstAmt =
                    ((parseFloat(e.node.acf.gst_percentage) *
                      parseFloat(e.node.acf.base_price)) /
                      100) *
                    incrementedValue;
                  var numFloat = parseFloat(gstAmt.toFixed(2));
                  arrOfGst.push(numFloat);
                  // console.log("incrementedValue arrOfGst", arrOfGst);
                }
              }
            }
            //----------------------END GST Total -----------------------
            array.map(arr => {
              if (arr.id == e.node.id) {
                // console.log("arr.id", arr.id);
                arr.quantity = e.node.quantity;
                var parseF = parseFloat(e.node.acf.delivery_charges);
                var totalDeliveryAmountPlus =
                  parseF + parseFloat(this.state.totalDeliveryAmount);
                totalDeliveryAmount = totalDeliveryAmountPlus.toFixed(2);
              }
            });
          }
        }
      });

      if (arrOfGst.length != 0) {
        var roundOfGstAmount = arrOfGst.reduce(getSum);
        totalGstAmount = roundOfGstAmount.toFixed(2);
        function getSum(total, num) {
          return total + num;
        }
      }

      this.setState(
        { myCartStateData: mystatecart },
        // totaling of amount and quantity
        () => {
          // console.log("mystatecart data", this.state.myCartStateData);

          var arr0fQuantity = [],
            arrOfAmount = [];

          this.state.myCartStateData.map((product, index) => {
            arr0fQuantity.push(product.node.quantity);
            var noOfQuantity =
              parseFloat(product.node.acf.price) * product.node.quantity;
            // console.log("noOfQuantity", noOfQuantity);
            arrOfAmount.push(noOfQuantity);
          });

          if (arr0fQuantity.length != 0) {
            var roundOfBillAmount = arr0fQuantity.reduce(getSum);
            totalQuantity = Math.round((roundOfBillAmount * 100) / 100);

            function getSum(total, num) {
              return total + num;
            }
          }

          if (arrOfAmount.length != 0) {
            var roundOfBillAmount = arrOfAmount.reduce(getSum);
            totalAmount = roundOfBillAmount.toFixed(2);

            function getSum(total, num) {
              return total + num;
            }
          }

          this.setState(
            {
              totalDeliveryAmount: totalDeliveryAmount,
              totalQuantity: totalQuantity,
              totalAmount: totalAmount,
              gst: totalGstAmount
            },
            () => {
              this.caluculateTotal(
                this.state.totalAmount,
                this.state.totalDeliveryAmount
              );
            }
          );
        }
      );

      window.localStorage.setItem("cartDetails", JSON.stringify(array));
    }
    //  quantityValue=incrementedValue
  }

  decrementValue(id, _this) {
    if (window.localStorage.getItem("cartDetails") == null) {
      window.localStorage.setItem("cartDetails", "[]");
    } else {
      var initialData = window.localStorage.getItem("cartDetails");
      var parse = JSON.parse(initialData);
      // console.log("before", parse);
      var array = parse;
      var totalDeliveryAmount;
      var arrObj = {},
        arrOfDeliveryCharge = [],
        arrOfGst = [];
      var mystatecart = this.state.myCartStateData;
      mystatecart.map((e, index) => {
        if (e.node.id == id) {
          let currentvalue = e.node.quantity;
          if (currentvalue > 1) {
            var decrementedValue = --currentvalue;
            arrObj["id"] = e.node.id;
            arrObj["quantity"] = e.node.quantity;
            mystatecart[index].node.quantity = decrementedValue;
            this.setState({ quantityValue: decrementedValue });
            var amountTot = decrementedValue * e.node.acf.price;
            mystatecart[index].node.priceTotalPerQuantity = amountTot;
            //---------------------- GST Total -----------------------
            if (e.node.acf.gst_percentage != null) {
              if (e.node.acf.base_price != null) {
                if (e.node.acf.gst_percentage != 0) {
                  var gstAmt =
                    ((parseFloat(e.node.acf.gst_percentage) *
                      parseFloat(e.node.acf.base_price)) /
                      100) *
                    decrementedValue;
                  var numFloat = parseFloat(gstAmt.toFixed(2));
                  arrOfGst.push(numFloat);
                  // console.log("decrementedValue arrOfGst", arrOfGst);
                }
              }
            }
            //----------------------END GST Total -----------------------
            array.map(arr => {
              if (arr.id == e.node.id) {
                // console.log("arr.id", arr.id);
                arr.quantity = e.node.quantity;
                var parseF = parseFloat(e.node.acf.delivery_charges);
                console.log(parseF, this.state.totalDeliveryAmount);
                var totalDeliveryAmountMinus =
                  parseFloat(this.state.totalDeliveryAmount) - parseF;
                totalDeliveryAmount = totalDeliveryAmountMinus.toFixed(2);
              }
            });
          } else {
            var parseF = 0;
            var totalDeliveryAmountMinus =
              parseFloat(this.state.totalDeliveryAmount) - parseF;

            totalDeliveryAmount = totalDeliveryAmountMinus.toFixed(2);
            console.log("totalDeliveryAmount", totalDeliveryAmount);
          }
        }
      });
      if (arrOfGst.length != 0) {
        var roundOfGstAmount = arrOfGst.reduce(getSum);
        totalGstAmount = roundOfGstAmount.toFixed(2);
        function getSum(total, num) {
          return total + num;
        }
      }
      console.log("totalDeliveryAmount", totalDeliveryAmount);
      window.localStorage.setItem("cartDetails", JSON.stringify(array));
      this.setState(
        { myCartStateData: mystatecart },
        // totaling of amount and quantity
        () => {
          // console.log("mystatecart data", this.state.myCartStateData);

          var arr0fQuantity = [],
            arrOfAmount = [],
            arrOfGst = [];

          this.state.myCartStateData.map((product, index) => {
            arr0fQuantity.push(product.node.quantity);
            var noOfQuantity =
              parseFloat(product.node.acf.price) * product.node.quantity;
            // console.log("noOfQuantity", noOfQuantity);
            arrOfAmount.push(noOfQuantity);
          });

          if (arr0fQuantity.length != 0) {
            var roundOfBillAmount = arr0fQuantity.reduce(getSum);
            totalQuantity = Math.round((roundOfBillAmount * 100) / 100);

            function getSum(total, num) {
              return total + num;
            }
          }

          if (arrOfAmount.length != 0) {
            var roundOfBillAmount = arrOfAmount.reduce(getSum);
            totalAmount = roundOfBillAmount.toFixed(2);

            function getSum(total, num) {
              return total + num;
            }
          }

          this.setState(
            {
              totalDeliveryAmount: totalDeliveryAmount,
              totalQuantity: totalQuantity,
              totalAmount: totalAmount,
              gst: totalGstAmount
            },
            () => {
              this.caluculateTotal(
                this.state.totalAmount,
                this.state.totalDeliveryAmount
              );
            }
          );
        }
      );
    }
  }

  addQuantity(evt) {
    //console.log("value", evt.target.value)
    this.setState({ quantityValue: evt.target.value });
  }

  delete(id, _this) {
    if (window.localStorage.getItem("cartDetails") == null) {
      window.localStorage.setItem("cartDetails", "[]");
    } else {
      var initialData = window.localStorage.getItem("cartDetails");
      var parse = JSON.parse(initialData);

      parse = parse.filter(function (obj) {
        return obj.id !== id;
      });

      // console.log("delete parse", typeof parse);
      // console.log("parse.length", parse.length);

      window.localStorage.setItem("cartDetails", JSON.stringify(parse));

      // this.setState({ refresh: !this.state.refresh }, () => {
      //   this.reload();
      // });

      if (parse.length == 0) {
        var dis = this;
        dis.setState({
          amountPayable: 0,
          gst: 0,
          totalAmount: 0,
          totalQuantity: 0,
          deliveryCharge: 0,
          cartData: [],
          myCartStateData: [],
          totalDeliveryAmount: 0
        });
        var arrOfGst = [];
        totalAmount = 0;
        totalQuantity = 0;
        totalGstAmount = 0;
      }

      this.reload();
    }
  }

  render() {
    const {
      gst,
      amountPayable,
      totalAmount,
      totalQuantity,
      deliveryCharge,
      totalDeliveryAmount
    } = this.state;
    // console.log(gst, amountPayable, totalAmount, totalQuantity);
    return (
      <div>
        <SweetAlert
          show={this.state.showAlert}
          title="Thank you for contacting us!"
          text="We will get in touch with you soon."
          onConfirm={() => this.setState({ showAlert: false })}
        />

        <SweetAlert
          show={this.state.withoutLogin}
          title="Please Login or Sign up to continue"
          onConfirm={() => {
            this.setState({ withoutLogin: false })
            navigate("/login")
          }}
        />

        <HeaderLight data={this.state.cartLength} />
        <div className="container1 NC-vision">
          <div className="row mar-tpr-tp position">
            {/* <div className="col-sm-12 col-md-12 col-xs-12 padTop15 flt-lft"> */}

            <div className="col-sm-8 col-md-8 col-xs-12 padTop15 flt-lft positionAbsolute">
              <div>
                {this.state.myCartStateData.length != 0 ? (
                  <div>
                    {this.state.myCartStateData.map(({ node }) => (
                      <div>
                        <div className="row" key={node.id}>
                          <div className="col-sm-3 col-md-3 col-xs-12 padTop15 flt-lft">
                            <div className="productimg">
                              <img
                                //  src="https://dashboard.nativecircle.in/wp-content/uploads/2019/03/shampoo1-480x485.jpg"
                                src={
                                  node.acf.image == null
                                    ? "http://dashboard.nativecircle.in/wp-content/uploads/2019/08/hs250.jpg"
                                    : node.acf.image.source_url
                                }
                                className=" img-fluid"
                                alt="Responsive image"
                              />
                            </div>
                          </div>

                          <div className="col-sm-9 col-md-9 col-xs-12 padTop15 flt-lft">
                            <div className="row marginBtm1">
                              <div className="col-9 col-sm-9 col-md-9 col-xs-9 mb-4 marginBottom0 height">
                                <p className="productName marginBottom0">
                                  {/* Herbal Shampoo */}
                                  {node.acf.product_title} &nbsp;&nbsp;
                                  {/*   <span className="discountStyle">
                                    {node.acf.discount == undefined ||
                                    node.acf.discount == ""
                                      ? 0
                                      : node.acf.discount}
                                    %
                                  </span> */}
                                </p>
                              </div>
                              <div className="col-3 col-sm-3 col-md-3 col-xs-3 text-center marginBottom0 height">
                                {/*  <div className="cartProdDiscount">
                          <p className="prodDiscountPercentage">{node.acf.discount == undefined || node.acf.discount == "" ? 0 :node.acf.discount}%</p>
                            </div> */}
                                <button
                                  type="button"
                                  className="btn"
                                  onClick={this.delete.bind(this, node.id)}
                                >
                                  <i
                                    className="fa fa-trash"
                                    style={{
                                      fontSize: "20px",
                                      color: "#f3bb48"
                                    }}
                                  />
                                </button>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-3 col-sm-3 col-md-3 col-xs-4  cartTxtLft">
                                <p>Size</p>
                              </div>
                              <div className="col-5 col-sm-6 col-md-6 col-xs-4  cartTxtRit">
                                {/* <p>
                                  {node.acf.product_quantity == null ||
                                  node.acf.product_quantity == ""
                                    ? "NA"
                                    : node.acf.product_quantity}
                                </p> */}
                                {node.acf.product_details.length == 1 ? (
                                  node.acf.product_details.map(product => (
                                    <p>{product.quantity}</p>
                                  ))
                                ) : (
                                    <p>combo pack</p>
                                  )}
                              </div>
                              <div className="col-4 col-sm-3 col-md-3 col-xs-4  cartTxtRit">
                                <p className="price">
                                  {" "}
                                  &#8377; {node.acf.price}
                                </p>
                                {/* 124.00 */}
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-3 col-sm-3 col-md-3 col-xs-4  cartTxtLft ">
                                <p>Type</p>
                              </div>
                              <div className="col-5 col-sm-6 col-md-6 col-xs-4  cartTxtRit ">
                                <p>{node.acf.product_type} </p>
                              </div>
                              <div className="col-4 col-sm-3 col-md-3 col-xs-4  cartTxtRit">
                                <p className="gstPTag">
                                  <span className="GSTText">GST</span>{" "}
                                  {node.acf.gst_percentage == null ||
                                    node.acf.gst_percentage == ""
                                    ? 0
                                    : node.acf.gst_percentage}
                                  %
                                </p>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-3 col-sm-3 col-md-3 col-xs-4  cartTxtLft">
                                <p>Unit</p>
                              </div>
                              <div className="col-5 col-sm-6 col-md-6 col-xs-4  cartTxtRit inlineFlex padding0">
                                <div
                                  className="product-circles"
                                  onClick={this.decrementValue.bind(
                                    this,
                                    node.id
                                  )}
                                >
                                  <img
                                    className="pointer"
                                    src="https://img.icons8.com/small/12/000000/expand-arrow.png"
                                  />
                                </div>
                                <input
                                  type="number"
                                  value={node.quantity}
                                  className="custom-text-number"
                                  id="productQuantity"
                                  readOnly
                                  onChange={this.addQuantity.bind(this)}
                                />
                                <div
                                  className="product-circles"
                                  onClick={this.incrementValue.bind(
                                    this,
                                    node.id
                                  )}
                                >
                                  <img
                                    className="pointer"
                                    src="https://img.icons8.com/small/12/000000/collapse-arrow.png"
                                  />
                                </div>
                              </div>
                              <div className="col-4 col-sm-3 col-md-3 col-xs-4  cartTxtRit padding0">
                                <p className="totalAmountPTag">
                                  <span className="totalAmountText">Total</span>{" "}
                                  &#8377;
                                  {node.priceTotalPerQuantity}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr />
                      </div>
                    ))}
                  </div>
                ) : (
                    <p className="NoProductP">
                      No products available in cart, please add products to carts
                    </p>
                  )}
              </div>
            </div>

            <div className="col-sm-4 col-md-4 col-xs-12 padTop15 verticalLine  positionFixed">
              <p className="paymentHeading">Payment details</p>
              <div className="row mrgBtm100">
                <div className="col-8 col-sm-8 col-md-8 col-xs-12 padTop15 payLeftDiv ">
                  <p>Total Items</p>
                </div>
                <div className="col-4 col-sm-4 col-md-4 col-xs-12 padTop15 payRitDiv">
                  <p>{this.state.cartLength}</p>
                </div>
                <div className="col-8 col-sm-8 col-md-8 col-xs-12 padTop15 payLeftDiv ">
                  <p>Total quantity</p>
                </div>
                <div className="col-4 col-sm-4 col-md-4 col-xs-12 padTop15 payRitDiv">
                  <p>{totalQuantity}</p>
                </div>
                <div className="col-8 col-sm-8 col-md-8 col-xs-12 padTop15 payLeftDiv ">
                  <p>Total amount</p>
                </div>
                <div className="col-4 col-sm-4 col-md-4 col-xs-12 padTop15 payRitDiv">
                  <p>{totalAmount}</p>
                </div>
                {/*  <div className="col-sm-8 col-md-8 col-xs-12 padTop15 payLeftDiv ">
                  <p>Total discount</p>
                </div>
                <div className="col-sm-4 col-md-4 col-xs-12 padTop15 payRitDiv">
                  <p>-100.00</p>
                </div> */}
                <div className="col-8 col-sm-8 col-md-8 col-xs-12 padTop15 payLeftDiv ">
                  <p>Inclusive GST</p>
                </div>
                <div className="col-4 col-sm-4 col-md-4 col-xs-12 padTop15 payRitDiv">
                  <p>{gst == "NaN" ? "0" : gst}</p>
                </div>
                <div className="col-8 col-sm-8 col-md-8 col-xs-12 padTop15 payLeftDiv ">
                  <p>
                    Delivery charges <span>/ item</span>
                  </p>
                </div>
                <div className="col-4 col-sm-4 col-md-4 col-xs-12 padTop15 payRitDiv">
                  <p>{totalDeliveryAmount}</p>
                </div>
              </div>

              <div>
                <hr className="payHrzLine" />
                <div className="col-8 col-sm-8 col-md-8 col-xs-12 padTop15 payLeftDiv ">
                  <p>Amount Payable</p>
                </div>
                <div className="col-4 col-sm-4 col-md-4 col-xs-12 padTop15 payRitDiv">
                  <p> &#8377; {amountPayable}</p>
                </div>
              </div>

              <div className="text-center prcd-chck-out">
                {amountPayable == 0 ? (
                  <div>
                    <p className="pCenterTag">No products</p>
                    <Link
                      className="proceedToCheckoutBtn"
                      htmlType="submit"
                      to="/products"
                    >
                      Shop now{" "}
                      <img
                        className="iconCheckout"
                        src="https://res.cloudinary.com/djoztpm50/image/upload/v1551181856/Native%20Circle/path.svg"
                      />
                    </Link>
                  </div>
                ) : (
                    <button
                      className="proceedToCheckoutBtn proceedmargintop0"
                      htmlType="submit"
                      //to="/payment-checkout"
                      // state={{
                      //   amountPayable: amountPayable,
                      //   products: this.state.myCartStateData,
                      //   totalQuantity: totalQuantity
                      // }}
                      onClick={() => this.setLocalData()}
                    >
                      Proceed to Checkout{" "}
                      <img
                        className="iconCheckout"
                        src="https://res.cloudinary.com/djoztpm50/image/upload/v1551181856/Native%20Circle/path.svg"
                      />
                    </button>
                  )}
              </div>
            </div>
            {/* </div> */}
          </div>
        </div>
        <div className="NC-paddingTop-50">{/* <Footer /> */}</div>
      </div>
    );
  }
}
export default MyCart;

export const pageQuery = graphql`
  query myCartProductsQuery {
    allWordpressWpNativeproduct {
      edges {
        node {
          id
          title
          slug
          date(formatString: "MMMM DD, YYYY")
        }
      }
    }
  }
`;
